<template>
  <div>
    <v-autocomplete
      :label="label || $t('customer')"
      v-model="customer"
      :items="customers"
      item-value="uuid"
      item-text="address.name_line"
      return-object
      @change="selectCustomer"
      :search-input.sync="customerSearch"
      :filter="customerFilter"
      :disabled="disabled"
      :filled="filled"
      :solo="solo"
      :hide-details="hideDetails"
      :dense="dense"
    >
      <template v-slot:no-data>
        <v-container>
          <div class="text--secondary text-center">
            <div class="pb-3">
              {{ $t('noCustomerWithThatNameFound') }}
            </div>
            <div>
              <v-btn
                block
                :color="primaryColor"
                :class="textColor(primaryColor)"
                @click="createCustomer"
              >
                {{ $t('createCustomer') }}
              </v-btn>
            </div>
          </div>
        </v-container>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data !== 'object'">
          <v-list-item-content v-text="data.item" />
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.address.name_line }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <div>{{ data.item.email }}</div>
              <div>{{ data.item.phone }}</div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <CustomerDialog
      :key="showCreateCustomer"
      v-model="newCustomer"
      :dialog="showCreateCustomer"
      :minimized="true"
      @updated="customerUpdated"
      @created="customerCreated"
      @dialog="customerDialogChanged"
      @closed="showCreateCustomer = false"
    />
  </div>
</template>

<script>
import CustomerDialog from "@/components/CustomerDialog"

export default {
	name: 'CustomerSelectionSimplified',
	components: {
		CustomerDialog
	},
	props: {
		customerUUID: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean
		},
		filled: {
			type: Boolean
		},
		hideDetails: {
			type: Boolean
		},
		label: {
			type: String,
			default: ''
		},
		dense: {
			type: Boolean
		},
		solo: {
			type: Boolean
		},
		noIcon: {
			type: Boolean
		},
		icon: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			showCreateCustomer: false,
			customerSearch: null,
			customer: null,
			newCustomer: {
				ssn: null,
        name_line: null
			}
		}
	},
	mounted() {
		if(this.customerUUID) {
			let c = this.customers.find(cu => cu.uuid === this.customerUUID)
			if(c) {
				this.customer = c
			}
		}
	},
	computed: {
		customers() {
			return this.$store.state.customers
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		},
	},
	methods: {
		customerFilter(item, queryText, itemText) {
			if(itemText.toLowerCase().includes(queryText.toLowerCase())) {
				return itemText
			}
			if(item.phone && item.phone.toString().toLowerCase().includes(queryText.toString().toLowerCase())) {
				return itemText
			}
			if(item.email && item.email.toLowerCase().includes(queryText.toLowerCase())) {
				return itemText
			}
		},
		createCustomer() {
      if(parseInt(this.customerSearch)) {
        this.newCustomer.ssn = this.customerSearch
      } else {
        this.newCustomer.name_line = this.customerSearch
      }
			this.showCreateCustomer = true
		},
		textColor(color) {
			return this.$store.getters.textButtonColor(color)
		},
		selectCustomer(customer) {
			this.customer = customer
			if(customer) {
				this.customerPhone = customer.phone
				this.customerEmail = customer.email
			} else {
				this.customerPhone = null
				this.customerEmail = null
			}
		},
		customerDialogChanged(dialog) {
			this.showCreateCustomer = dialog
		},
		customerCreated(customer) {
			this.showCreateCustomer = false
			this.customer = this.$store.getters.getCustomerByUUID(customer.uuid)
		},
		customerUpdated(customer) {
			this.showCreateCustomer = false
			this.customer = this.$store.getters.getCustomerByUUID(customer.uuid)
		},
	},
	watch: {
		customer(value) {
			if(value) {
				this.$emit('input', value.uuid)
			}
		}
	}
}
</script>

<style scoped>

</style>